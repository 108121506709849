import { useStaticQuery, graphql } from 'gatsby';
import { ImageSharp } from 'models/image';

interface LogoQueryResult {
  logo: ImageSharp;
  logoText: ImageSharp;
  openGraphImage: ImageSharp;
}
export const useLogoData = () => {
  const data = useStaticQuery<LogoQueryResult>(graphql`
    query {
      logoText: file(absolutePath: { regex: "/evan-library/logo-text.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/evan-library/logo.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      openGraphImage: file(absolutePath: { regex: "/evan-library/open-graph.jpg/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return data;
};
