// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-category-page-template-tsx": () => import("./../src/templates/CategoryPageTemplate.tsx" /* webpackChunkName: "component---src-templates-category-page-template-tsx" */),
  "component---src-templates-post-page-template-tsx": () => import("./../src/templates/PostPageTemplate.tsx" /* webpackChunkName: "component---src-templates-post-page-template-tsx" */),
  "component---src-templates-posts-page-template-tsx": () => import("./../src/templates/PostsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-posts-page-template-tsx" */),
  "component---src-templates-tag-page-template-tsx": () => import("./../src/templates/TagPageTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-page-template-tsx" */)
}

