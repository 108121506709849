import React, { ReactNode, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import SEO from 'components/SEO';
import theme from 'utils/theme';
import firebase from 'gatsby-plugin-firebase';

interface Props {
  children: ReactNode;
}

export const App = ({ children }: Props) => {
  useEffect(() => {
    firebase.analytics();
  }, []);

  return (
    <>
      <SEO />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
};

export default App;
