import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetadata } from 'models/site';

interface QueryResult {
  site: { siteMetadata: SiteMetadata };
}
export const useSiteMetadata = () => {
  const { site } = useStaticQuery<QueryResult>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          author {
            name
            summary
            email
          }
          description
          siteUrl
          social {
            github
            linkedin
            rss
          }
          languages
          defaultLanguage
        }
      }
    }
  `);

  return site.siteMetadata;
};
