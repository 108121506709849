import { ReactNode, useEffect } from 'react';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { useRecoilState } from 'recoil';
import { languageAtom } from 'atoms/language';
import { popularPostsAtom } from 'atoms/popularPosts';
import { SupportedLanguages } from './models/site';
import firebase from 'gatsby-plugin-firebase';
import useAsyncEffect from 'hooks/useAsyncEffect';

interface Props {
  children: ReactNode;
  pageContext: {
    lang: SupportedLanguages;
  };
}
const PageRoot = ({ children, pageContext }: Props) => {
  const { defaultLanguage } = useSiteMetadata();
  const [, setLanguage] = useRecoilState(languageAtom);
  const [, setPopularPosts] = useRecoilState(popularPostsAtom);

  useEffect(() => {
    setLanguage(pageContext.lang ?? defaultLanguage);
  }, [setLanguage, pageContext.lang, defaultLanguage]);

  useAsyncEffect(async () => {
    const snapshot = await firebase.database().ref('views').orderByValue().limitToLast(10).once('value');

    let posts: string[] = [];
    snapshot.forEach(v => {
      const key = v.key ?? '';
      posts = [...posts, key];
    });
    posts.reverse();

    setPopularPosts(posts);
  }, []);

  return children;
};

export default PageRoot;
