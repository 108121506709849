import React from 'react';
import { RecoilRoot } from 'recoil';
import App from 'src/App';
import Page from 'src/Page';
import 'normalize.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'src/styles/prism-theme.css';
import 'src/styles/hacks.css';
import 'katex/dist/katex.min.css';
import 'firebase/analytics';
import 'firebase/database';

export const wrapRootElement = ({ element }) => {
  return (
    <RecoilRoot>
      <App>{element}</App>
    </RecoilRoot>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <Page {...props}>{element}</Page>;
};
